exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-aplikacja-mobilna-js": () => import("./../../../src/pages/aplikacja-mobilna.js" /* webpackChunkName: "component---src-pages-aplikacja-mobilna-js" */),
  "component---src-pages-dla-klientow-twojego-biznesu-js": () => import("./../../../src/pages/dla-klientow-twojego-biznesu.js" /* webpackChunkName: "component---src-pages-dla-klientow-twojego-biznesu-js" */),
  "component---src-pages-dom-lub-firma-js": () => import("./../../../src/pages/dom-lub-firma.js" /* webpackChunkName: "component---src-pages-dom-lub-firma-js" */),
  "component---src-pages-en-about-us-js": () => import("./../../../src/pages/en/about-us.js" /* webpackChunkName: "component---src-pages-en-about-us-js" */),
  "component---src-pages-en-charge-up-js": () => import("./../../../src/pages/en/charge-up.js" /* webpackChunkName: "component---src-pages-en-charge-up-js" */),
  "component---src-pages-en-contact-confirmation-js": () => import("./../../../src/pages/en/contact-confirmation.js" /* webpackChunkName: "component---src-pages-en-contact-confirmation-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-home-or-business-js": () => import("./../../../src/pages/en/home-or-business.js" /* webpackChunkName: "component---src-pages-en-home-or-business-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-mobile-app-js": () => import("./../../../src/pages/en/mobile-app.js" /* webpackChunkName: "component---src-pages-en-mobile-app-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-submit-location-js": () => import("./../../../src/pages/en/submit-location.js" /* webpackChunkName: "component---src-pages-en-submit-location-js" */),
  "component---src-pages-en-terms-of-use-js": () => import("./../../../src/pages/en/terms-of-use.js" /* webpackChunkName: "component---src-pages-en-terms-of-use-js" */),
  "component---src-pages-en-your-customers-js": () => import("./../../../src/pages/en/your-customers.js" /* webpackChunkName: "component---src-pages-en-your-customers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-naladuj-sie-js": () => import("./../../../src/pages/naladuj-sie.js" /* webpackChunkName: "component---src-pages-naladuj-sie-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-zaproponuj-lokalizacje-js": () => import("./../../../src/pages/zaproponuj-lokalizacje.js" /* webpackChunkName: "component---src-pages-zaproponuj-lokalizacje-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

